<template>
  <div class="List">
    <van-nav-bar title="新增业务员" left-arrow @click-left="onClickLeft">
      <!-- <template #right>
        <UserIcon />
      </template> -->
    </van-nav-bar>
    <van-notice-bar
      text="新增业务员后，业务员可通过手机号进行登录"
    />
    <van-form validate-first @submit="onSubmit">
      <van-field
        v-model="formdate.name"
        name="业务员名称"
        label="业务员名称"
        maxlength="10"
        placeholder="请输入业务员名称"
        :rules="[{ required: true, message: '请填写业务员名称' }]"
      />
      <van-field
        v-model="formdate.certNo"
        name="身份证号"
        label="身份证号"
        placeholder="请输入业务员身份证号"
        :rules="[
          { required: true, message: '请输入业务员身份证号' },
          { pattern: idCodeReg, message: '身份证号格式错误！' },
        ]"
      />
      <van-field
        v-model="formdate.mobile"
        name="业务员账号"
        label="手机号"
        placeholder="请输入业务员手机号"
        :rules="[
          { required: true, message: '请输入业务员手机号' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
        ]"
      />
      <van-field
        v-model="formdate.jobDesc"
        name="岗位"
        label="岗位"
        maxlength="20"
        placeholder="请输入业务员岗位"
        readonly
        :rules="[{ required: true, message: '请填写业务员岗位' }]"
      />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import Vue from "vue";
import Api from "@/api/user";
import { Form, Field, Toast, NoticeBar } from "vant";
import { idCodeReg } from "@/plugins/common";
Vue.use(Form);
Vue.use(Field);
Vue.use(NoticeBar);
export default {
  data() {
    return {
      idCodeReg,
      userBaseInfo: {},
      formdate: {
        name: "",
        certNo: "",
        mobile: "",
        job: "1",
        jobDesc: "家访人员"
      },
    };
  },
  async mounted() {
    const userBaseInfo = await Api.baseInfo();
    userBaseInfo.success && (this.userBaseInfo = userBaseInfo.data);
  },
  methods: {
    async onSubmit(values) {
      console.log("submit", values);
      await this.operatorRegister();
    },
    // 员工注册
    async operatorRegister() {
      if (this.sbumiting) return;

      try {
        this.sbumiting = true;
        this.formdate.orgNo = this.userBaseInfo.resOperatorDTO.orgNo; // 机构编号
        const result = await Api.operatorRegister(this.formdate);
        if (result.success) {
          Toast.success({
            message: result.message,
          });
          this.formdate.name = "";
          this.formdate.certNo = "";
          this.formdate.mobile = "";
          this.$router.push({
            name: "salesmanList",
          });
        }
      } catch (error) {
        Toast.fail(error);
      } finally {
        this.sbumiting = false;
      }
    },
  },
};
</script>